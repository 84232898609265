<template>
  <div class="container">
    <div class="row" v-for="group in traitGroups" :key="group.name">
      <div class="col-12">
        <strong>{{ group.name }}</strong>
      </div>
      <template v-for="trait in traits" :key="trait.key">
        <div v-if="trait.group === group.name" v-bind:class="'trait col-3 text-center score-' + trait.color"  @click="window.location = '/browse/' + $route.params.collection + '/' + trait.key">
          <strong>{{ trait.value }}</strong><br/>
          <template v-if="trait.score !== null">
            <img src="/images/v2/rarify-icon.png"> {{ trait.score }} -
          </template>{{ trait.occurrances }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      traits: [],
      traitGroups: [],
    }
  },
  mounted() {
    fetch(this.apiUrl + '/?collection=' + this.$route.params.collection + '&action=stats').then((response) => {
      return response.json();
    }).then((result) => {
      this.traits = result.stats;
      this.traitGroups = result.collection.traitGroups;
    });
  }
}
</script>