<template>

  <div class="container">
    <div class="row header">
      <div class="col-2">
        <img src="/images/v2/rarify.png" class="img-fluid" />
      </div>
      <div class="col-10">
        <h1>Rarify</h1>
        Verify and quantify NFT rarity
      </div>
    </div>

    <div class="container">
      <ul class="nav">
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="/">About</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/collections">Collections</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/wallet">Wallet</a>
        </li>
        <li class="nav-item" v-if="$route.params.collection">
          <a class="nav-link" v-bind:href="'/' + $route.params.collection + '/browse/'">Browse</a>
        </li>
        <li class="nav-item" v-if="$route.params.collection">
          <a class="nav-link" v-bind:href="'/' + $route.params.collection + '/traits/'">Traits</a>
        </li>
      </ul>
    </div>
  </div>
  <div class="container">
    <router-view/>
    <div class="row footer">
      <div class="col-md-12">
        &nbsp;
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Rarify',
}
</script>

<style>
div.row {
  margin: 1em;
}

.score-0    { background-color: #b5ecdd; }
.score-1    { background-color: #c0e5f9; }
.score-2    { background-color: #efdcf9; }
.score-3    { background-color: #ffd4dd; }
.score-4    { background-color: #fae1b6; }

div.row.header {
  margin-top: 0;
  padding: 1em;
  color: #FFF;
  background-image: linear-gradient(90deg, #0ac18e, #00bbd2, #2fa9ee, #859ef9, #cb8deb, #ff79c5, #ff728e, #ff8254, #f09e0e);
}

div.row.header .col-10 {
  vertical-align: middle;
}

div.row.footer div {
  background-color: #EEE;
}

img.img-big {
  border-radius: 10px;
  filter: drop-shadow(0 3px 3px rgba(0, 0, 0, 0.3));
}

div.trait {
  padding: 1px;
  text-decoration: none;
  cursor: pointer;
}

div.trait:hover {
  filter: drop-shadow(3px 3px 5px rgb(150,150,150) );
}
</style>