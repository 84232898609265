<template>
  <div class="container">
    <div v-if="loading" class="row text-center">
      <div class="col-12">
        Loading... Hold on.
      </div>
    </div>
    <div class="row">
      <div class="col-2 p-3" v-for="nft in nfts" :key="nft.tokenId">
        <div class="card rounded shadow" style="cursor: pointer" @click="window.location = nft.collection + '/token/' + nft.tokenId">
          <img v-bind:src="nft.image" class="card-img-top" v-bind:alt="nft.name">
          <div class="card-body text-center">
            <p class="card-text">
              #{{nft.tokenId}}<br/>
              Rank {{ nft.rank }}<br/>
              <img src="/images/v2/rarify-icon.png"> {{nft.score}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ethers} from "ethers";
import abi from "../erc721.json";

export default {
  data() {
    return {
      collections: [],
      nfts: [],
      amount: 0,
      loading: true,
    }
  },
  mounted() {
    let provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    provider.send("eth_requestAccounts", []).then(() => {
      let signer = provider.getSigner();
      fetch(this.apiUrl + '/?action=collections').then((response) => {
        return response.json();
      }).then((result) => {
        this.collections = result.collections;
        const loadContractTokens = (address, collection) => {
          if(collection.contract == "") {
            return;
          }
          let contract = new ethers.Contract(collection.contract, abi, signer);
          contract.balanceOf(address).then(async (balance) => {
            this.amount += balance.toNumber();
            for(var j = 0;j < balance;j++) {
              contract.tokenOfOwnerByIndex(address, j).then((token) => {
                fetch(this.apiUrl + '/?action=nft&collection=' + collection.name + '&token=' + token).then((response) => {
                  return response.json();
                }).then((result) => {
                  this.nfts.push({
                    rank: result.rank,
                    collection: collection.name,
                    tokenId: token,
                    score: result.score,
                    image: result.image
                  });
                  if(this.nfts.length === this.amount) {
                    this.loading = false;
                  } else {
                    this.loading = true;
                  }
                  console.log(this.amount);
                  console.log(this.nfts.length);
                });
              });
            }
          });
        }
        signer.getAddress().then((address) => {
          for (var i in this.collections) {
            loadContractTokens(address, this.collections[i]);
          }
        });
      });
    });
  }
}
</script>
