<template>
  <div class="container">
    <div class="row">
      <div class="col-4 text-center">
        <template v-if="nft">
          <h3>{{nft.name}}</h3>
          <img v-bind:src="apiUrl + '/?collection=' + collection + '&action=image&token=' + nft.id" v-bind:alt="nft.id" class="img-fluid img-big">
          <div class="text-center" style="padding: 5px;">
            <img src="/images/v2/rarify-icon.png"> <strong>{{ nft.score }}</strong><br/>
            Rank: {{nft.rankRange}} <br/>
          </div>
        </template>
      </div>
      <div class="col-8">
        <div class="container" v-if="nft">
          <div class="row">
            <template v-for="trait in nft.traits" :key="trait.key">
              <div v-bind:class="'trait col-3 text-center score-' + trait.color" @click="window.location = '/browse/' + $route.params.collection + '/' + trait.key">
                {{ trait.group }}<br/>
                <strong>{{ trait.value }}</strong><br/>
                <img src="/images/v2/rarify-icon.png"> {{ trait.score }}
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nft: null,
      collection: null
    }
  },
  mounted() {
    this.collection = this.$route.params.collection;
    fetch(this.apiUrl + '/?action=nft&collection=' + this.$route.params.collection + '&token=' + this.$route.params.id).then((response) => {
      return response.json();
    }).then((result) => {
      this.nft = result;
    });
  }
}
</script>