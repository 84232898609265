<template>
  <div class="row">
    <div class="col-12">
      <h3>Collections</h3>
    </div>
  </div>
  <div v-if="loading" class="row text-center">
    <div class="col-12">
      Loading... Hold on.
    </div>
  </div>
  <div class="collection row">
    <template v-for="col in collections" :key="col">
      <div class="col-6">
        <div class="row">
          <div class="col-4 text-center" @click="$router.push('/' + col.name + '/browse')" style="cursor: pointer">
            <img class="img-fluid img-big" v-bind:src="apiUrl + '/?action=img&collection=' + col.name + '&token=' + col.image" />
          </div>
          <div class="col-8">
            <h3>{{ col.display }}</h3>
            <div v-html="col.description"></div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      collections: [],
      loading: true,
    }
  },
  mounted: function() {
    fetch(this.apiUrl + '/?action=collections').then((response) => {
      return response.json();
    }).then((result) => {
      this.collections = result.collections;
      this.loading = false;
    });
  }
}
</script>

<style>
  .collection.row {
    margin: 1em;
  }
</style>