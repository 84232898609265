import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router'
import 'bootstrap/scss/bootstrap.scss'
import Collections from "@/components/Collections";
import Browse from "@/components/Browse";
import Wallet from "@/components/Wallet";
import Token from "@/components/Token";
import Traits from "@/components/Traits";
import Home from "@/components/Home";

export let app = createApp(App);

const routes = [
    { path: '/collections', component: Collections },
    { path: '/wallet', component: Wallet },

    { path: '/:collection/browse', component: Browse },
    { path: '/:collection/browse/:filter', component: Browse },
    { path: '/:collection/traits', component: Traits },
    { path: '/:collection/token/:id', component: Token },

    { path: '/browse/:collection', component: Browse },
    { path: '/browse/:collection/:filter', component: Browse },
    { path: '/traits/:collection', component: Traits },
    { path: '/token/:collection/:id', component: Token },

    { path: '/', component: Home },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
});

app.config.globalProperties.window = window;
/**/
app.config.globalProperties.apiUrl = 'https://rarify.cash/api';
/*/
app.config.globalProperties.apiUrl = 'http://localhost:9050';
/**/

app.use(router);
app.mount('#app');
