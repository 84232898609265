<template>
  <div class="container">
    <div v-if="loading" class="row text-center">
      <div class="col-12">
        Loading... Hold on.
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <template v-if="currentPage > 0">
          <span @click="showPage(currentPage - 1)" style="cursor: pointer">
            &laquo; Previous
          </span>
        </template>
      </div>
      <div class="col-6 text-end">
        <template v-if="typeof pages[currentPage + 1] !== 'undefined'">
          <span @click="showPage(currentPage + 1)" style="cursor: pointer">
            Next &raquo;
          </span>
        </template>
      </div>
      <div class="col-12 text-center">
        <template v-for="page in pages" :key="page">
          <template v-if="page === 0 || page === 1 || page === 2 || page === currentPage - 2 || page === currentPage - 1  || page === currentPage || page === currentPage + 1 || page === currentPage + 2 || page === pages[pages.length - 3] || page === pages[pages.length - 1] || page === pages[pages.length - 2]">
            <span class="page-item" @click="showPage(page)" style="cursor: pointer;">
              <template v-if="page === currentPage">
                <strong style="color: #0AC18E; border: 1px solid #0AC18E; padding: 3px;border-radius: 3px;">{{ page + 1 }}</strong>
              </template>
              <template v-if="page !== currentPage">
                {{ page + 1 }}
              </template>
            </span>&nbsp;
          </template>
        </template>
      </div>
    </div>

    <div class="row">
      <div class="col-2 p-3" v-for="rank in display" :key="rank.name">
        <div class="card rounded shadow" @click="$router.push('/' + this.collection + '/token/' + rank.id)" style="cursor: pointer;">
          <img v-bind:src="apiUrl + '?collection=' + this.collection + '&action=image&token='+rank.id" class="card-img-top" v-bind:alt="rank.name">
          <div class="card-body text-center">
            <p class="card-text">
              {{ rank.name }}<br/>
              <img src="/images/v2/rarify-icon.png"> {{ rank.score }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <template v-if="currentPage > 0">
          <span @click="showPage(currentPage - 1)" style="cursor: pointer">
            &laquo; Previous
          </span>
        </template>
      </div>
      <div class="col-6 text-end">
        <template v-if="typeof pages[currentPage + 1] !== 'undefined'">
          <span @click="showPage(currentPage + 1)" style="cursor: pointer">
            Next &raquo;
          </span>
        </template>
      </div>
      <div class="col-12 text-center">
        <template v-for="page in pages" :key="page">
          <template v-if="page === 0 || page === 1 || page === 2 || page === currentPage - 2 || page === currentPage - 1  || page === currentPage || page === currentPage + 1 || page === currentPage + 2 || page === pages[pages.length - 3] || page === pages[pages.length - 1] || page === pages[pages.length - 2]">
            <span class="page-item" @click="showPage(page)" style="cursor: pointer;">
              <template v-if="page === currentPage">
                <strong style="color: #0AC18E; border: 1px solid #0AC18E; padding: 3px;border-radius: 3px;">{{ page + 1 }}</strong>
              </template>
              <template v-if="page !== currentPage">
                {{ page + 1 }}
              </template>
            </span>&nbsp;
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      collections: [],
      collection: '',
      display: [],
      currentPage: 0,
      pageSize: 48,
      pages: [],
      ranks: [],
      filter: null,
      view: 'browse',
      loading: true,
    }
  },
  mounted() {
    this.loadCollection(this.$route.params.collection);
    if(typeof this.$route.params.filter !== 'undefined') {
      this.setFilter(this.$route.params.filter)
    }
  },
  methods: {
    setFilter(filter) {
      this.filter = filter;
      this.view = 'browse';
      this.showPage(0);
    },
    loadCollection: function(collection) {
      this.filter = null;
      this.collection = collection;
      fetch(this.apiUrl + '/?collection=' + this.collection + '&action=stats').then((response) => {
        return response.json();
      }).then((result) => {
        this.pages = [];
        for(var i = 0;i < result.collection.pages;i++) {
          this.pages.push(i);
        }
        this.showPage(0, this.pageSize);
      });
    },
    showPage: function(pg) {
      this.currentPage = pg;
      var url = this.apiUrl + '/?collection=' + this.collection + '&action=ranks&page=' + pg;
      if(this.filter != null) {
        url += '&filter=' + this.filter;
      }
      fetch(url).then((response) => {
        return response.json();
      }).then((result) => {
        this.ranks = result.ranks;
        this.display = result.ranks;
        this.pages = [];
        for(var i = 0;i < result.collection.pages;i++) {
          this.pages.push(i);
        }
        this.loading = false;
      });
    },
  }
}
</script>

<style>
.page-item {
  margin: 10px;
}
</style>