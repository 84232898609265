<template>
  <div class="row">
    <div class="col-md-12">
      <h1>Why Rarify</h1>
      <p>
        With Rarify, verify and quantify NFT rarity using a novel, point-awarding methodology! Traits from each collection are split into 5 rarity tiers that award points. The resulting Rarify scores are the sum of these incremental trait points for each NFT.
      </p>
      <p>
        Rarify intentionally creates ties for grouped ranking so that NFT owners can focus on evaluating art within tied tiers instead of absolute rankings. Furthermore, collection creators and builders can use these incremental trait points and/or resulting Rarify scores for gamefi, staking, or other metaverse integrations.
      </p>
      <p>
        In addition to BCH collections, the Rarify team will process top NFT collections from other ecosystems (e.g. ETH), get people talking about Rarify's innovative approach, and have them look at BCH collections through Rarify.cash at the same time as they check their collections. We want to get more eyes interested in and into our BCH ecosystem, and to grow!
      </p>
      <h3>Get in touch!</h3>
      <p>
        Join our Telegram group <a href="https://t.me/RarifyTalk" target="_blank">t.me/RarifyTalk</a>
      </p>
    </div>
    <h3>Support our developments</h3>
    <div class="col-md-1">
      <img src="@/assets/tip-sbch.png" class="img-fluid" width="100" />
    </div>
    <div class="col-md-5" style="height: 150px; margin: auto;">
      <a href="https://www.smartscan.cash/address/0xc6B1FbFfCB590F23c38dD01264874c9cEa310FF1" target="_blank">0xc6B1FbFfCB590F23c38dD01264874c9cEa310FF1</a>
    </div>

    <div class="col-md-1">
      <img src="@/assets/tip-bch.svg" class="img-fluid" width="100" />
    </div>
    <div class="col-md-5" style="height: 150px; margin: auto;">
      <a href="https://blockchair.com/bitcoin-cash/address/qr6ma5muxmz6ve88ejl05l77a3e4thurhq8c9px9x8" target="_blank">bitcoincash:qr6ma5muxmz6ve88ejl05l77a3e4thurhq8c9px9x8</a>
    </div>
  </div>
</template>
<script>
export default {

}
</script>